@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://db.onlinewebfonts.com/c/1dc8ecd8056a5ea7aa7de1db42b5b639?family=Gilroy');

main {
  padding: 0;
}

body {
  margin: 0;
  padding: 0 !important;
  box-sizing: border-box;
  font-family: 'Gilroy', sans-serif;
  font-size: 13px;
  line-height: 1.6;
}

h1,h2,h3,h4,h5,h6 {
  font-family: 'Raleway', sans-serif;
}
* {
  box-sizing: inherit;
}

ul, ol {
  // doesn't do RTL, it break a sidebar
  padding-left: 15px;
  margin-bottom: 0;
  list-style-image: url('../../shared/img/circle.svg');
}

a {
  color: $color-blue;
  transition: all 0.3s;

  &:hover {
    text-decoration: none;
    color: $color-blue-hover;
  }
}

img {
  width: 100%;
}

.page-heading{
  // text-transform: capitalize;
  color: #383938 !important;
  font-size: 36px;
  margin-bottom: 25px;
  font-weight: 700;
}

.page-breadcrumbs {
  text-align: left;
  padding-top: 0px;
  margin-bottom: 30px;

  span {
    color: #009ADC;
  }
  
  a {
    position: relative;
    color: #787878;
    font-weight: 400;

    &:after {
      content: '|';
      margin: 0 10px;
    }
  }
}

.footer-container {
  background-color: #064B65;
  margin-left: -10px;

  .container {
    padding-top: 15px;
    padding-bottom: 15px;

    .footer-left {
      font-size: 14px;
      color: #fff;
    
      span{
        opacity: 0.75;
      }

      a{
        color: white;
      }
    }
  }
}


.user-loggedin.container__wrap > div:not(.footer-container) {
  min-height: calc(100vh - 142px);
}

.btn:before{
  left: 0;
}

.blink {
  animation: blink 0.5s alternate-reverse infinite;
}

@keyframes blink {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}