.property-container, .property-container h2, .property-container h3 {
    color: white;
}
.property-container h2 {
    margin-bottom: 30px;
    text-decoration: underline;
}

.hearing-date-out-cont {
    position: relative;
}

.hearing-date-out-cont .fa {
    position: absolute;
    top: -5px;
    left: -11px;
}

.percent-sav h3 {
    background: white;
    padding: 5px 10px;
    border-radius: 4px;
    color: #002D62;
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 20px;
}

.percent-sav h3 span {
    font-weight: 400;
}
.edit-property-form{
    width: 100%;
}
.property-search-container .card-body .row,
#add_billing_form {
    max-width: 700px;
    margin: auto;
}

.property-search-container form input,
.property-search-container form select {
    width: 100%;
    padding: 8px;
    border: 1px solid rgb(118, 118, 118);
}
.form__form-group-two > * {
    flex: 0 0 48%;
}
.form__form-group-two {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.property-search-btns button svg,
.property-create-btns button svg{
    margin-bottom: 3px;
}
.property-create-btns .create__btn svg path {
    fill: #fff;
}
/* .property-create-btns .create__btn,
.add-billing-btn,
.edit-billing,
.deleteProperty-btn,
.general-file-uploader button {
    background: #009adc !important;
    border-color: #009adc !important;
    border-radius: 2px;
    color: #fff;
    transition: all 0.3s ease-in-out;
    -webkit-box-shadow: 0px 2px 6px 0px #009adc80 !important;
    box-shadow: 0px 2px 6px 0px #009adc80 !important;
} */
.property_notes button:after,
.property_notes button:before,
.add-billing-btn:before,
.add-billing-btn:after,
.edit-billing:before,
.edit-billing:after,
.deleteProperty-btn:after,
.deleteProperty-btn:before, 
.changeHearingStatus-btn:before, .changeHearingStatus-btn:after,
.property-search-btns .search-btn:before, .property-search-btns .search-btn:after{
    display: none;
}
.add-billing-btn{
    margin: 0;
}
.property_search_form select,
.property-reg-form select {
    width: 100%;
    padding: 13px 8px !important;
    border-radius: 2px;
    border: 1px solid #E9ECEF !important;
}

.property_search_form input,
.property-reg-form input {
    width: 100%;
    border: 1px solid #E9ECEF !important;
    padding: 13px 15px !important;
}
.property_search_form .form__form-group-label,
.property-reg-form .form__form-group-label{
    font-weight: 700;
}
.property-registration-form-container form {
    max-width: 700px;
    margin: auto;
}
.property-reg-form textarea{
    width: 100%;
    border: 1px solid #E9ECEF !important;
    padding: 13px 15px !important;
}
.property-create-btns{
    display: flex;
    justify-content: flex-end;
}
.property-reg-form .form__form-group-property-fields > div {
    flex: 0 0 calc(50% - 10px);
}
.property-reg-form .form__form-group-property-fields {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.property-reg-form h3 {
    font-weight: 700;
    font-size: 20px;
}

.propertyNotes_wrapper textarea {
    width: 100%;
    border: none;
    background-color: #fff;
    padding: 15px;
    border-radius: 4px;
    color: black;
    border: 1px solid #EAEDF2 !important;
}
.propertyNotes_wrapper textarea::placeholder{
    color: #787878;
}

/* .property_notes {
    padding: 20px 0;
} */
.property-map iframe {
    width: 100%;
}

.deletePropertyModal svg path {
    fill: #ffbc00;
}

.deletePropertyModal svg {
    width: 45px;
    height: 45px;
}

.deletePropertyModal .modal-dialog {
    max-width: 300px;
}

.deletePropertyModal .modal-content {
    padding: 20px 20px 10px 20px;
}

.deletePropertyModal button {
    margin-top: 20px;
}

.deletePropertyModal h3 {
    font-size: 18px;
    margin-bottom: 20px;
}
.property-account-files h3, .general-results-files h3 {
    color: #383938;
    font-size: 15px;
    font-weight: 700;
}
/* .property-account-files > div,
.general-results-files > div {
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.1);
} */

.general-files-list {
    border: 1px solid #FFA647;
    width: 100%;
    padding: 20px 15px;
    border-radius: 2px;
    margin-top: 20px !important;
    border-radius: 5px;
}
.general-files-list > div {
    display: flex;
    align-items: center;
}
.docu-delete-wrapper {
    justify-content: space-between;
}
.docu-delete-wrapper > div:last-child {
    flex: 0 0 30%;
}


.general-files-list  .filetype {
    background-color: #009ADC;
    color: white;
    font-weight: 600;
    line-height: 45px;
    width: 45px;
    border-radius: 4px;
    display: inline-block;
    text-align: center;
    margin-right: 15px;
    font-size: 11px;
    font-family: 'Raleway', sans-serif;
}

.general-files-list  .filename {
    flex: 1 1;
    color: #383938;
    font-weight: 600;
    font-size: 14px;
    font-family: 'Raleway', sans-serif;
    /* padding-top: 14px; */
}
.general-files-list .fileicon > div:first-child > span {
    font-family: Raleway;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #383938;
    margin-right: 10px;
}
.controlled-row{
    margin: 0 -30px;
}
.general-files-list  .fileicon {
    /* padding: 15px 0 0; */
}
.general-files-list  .fileicon svg path{
    /* fill: #98a6ad; */
}
/* .general-file-uploader button {
    color: #6c757d;
    padding: 13px 20px;
    border: none;
} */
.property-map-wrapper {
    padding-left: 0;
    padding-right: 0;
}
.property-status-hearing {
    font-size: 15px;
}

.editPropertyStatus {
    cursor: pointer;
    margin-left: 20px;
}

.editPropertyStatus svg {
    height: 15px;
    width: 15px;
}

.hearingStatusModal .modal-dialog {
    max-width: 800px;
}

.hearingStatusModal .modal-dialog svg path {
    fill: #FFBC00;
}

.hearingStatusModal .modal-dialog svg {
    height: 50px;
    width: 50px;
}
.hearingStatusModal h3{
    color: #6c757d;
    font-size: 20px;
}
.hearingStatusModal p {
    text-align: left;
    font-size: 15px;
    font-weight: 700;
    color: #6c757d;
}

.hearingStatusModal input {
    width: 100%;
    padding: 13px 10px;
    margin-top: 10px;
    background: #f1f3fa;
    border: none;
    font-size: 15px;
}
.hearingStatusModal .modal-content {
    border-radius: 4px;
    padding: 30px 25px 10px 25px;
}

.hearingstatus-btns {
    padding-top: 20px;
}
.hearingstatus-btns button:not(:last-child) {
    margin-right: 10px;
}

#add_billing_form h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 1;
}
.form__form-group-three{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.form__form-group-three > * {
    flex: 0 0 31%;
}

.form__form-group-four{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.form__form-group-four > * {
    flex: 0 0 23%;
}
