.csacee{
    min-width: unset;
}
.markReadyModal .modal-body svg {
    width: 50px;
    height: 50px;
}

.markReadyModal .modal-body svg path {
    fill: #ffbc00;
}

.markReadyModal .modal-body h3 {
    font-size: 22px;
    font-weight: 700;
    margin: 10px 0 20px 0;
}

.markReadyModal .modal-body p {
    font-size: 15px;
    margin-bottom: 20px;
}

.markReadyModal .modal-content {
    padding: 20px 20px 10px 20px;
}
.newcomplete-btn{
    color: #313a46 !important;
    background-color: #ffbc00 !important;
    border-color: #ffbc00 !important;
    -webkit-box-shadow: 0px 2px 6px 0px rgba(255, 188, 0, 0.5) !important;
    box-shadow: 0px 2px 6px 0px rgba(255, 188, 0, 0.5) !important;
}
.newcomplete-btn:before,
.newcomplete-btn:after{
    display: none;
}