.filter-invoice-report h3 {
    margin-bottom: 20px;
}
.filter-invoice-report-form{
    max-width: 700px;
    margin: auto;
}
.filter-invoice-report-form select {
    width: 100%;
    padding: 8px;
}
.tax_year_wrapper,
.zipcode_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
.zipcode_wrapper{
    align-items: flex-start;
}

.tax_year_wrapper button,
.zipcode_wrapper button {
    margin-bottom: 0;
}

.tax_year_wrapper > *,
.zipcode_wrapper > * {
    margin: 0 10px;
}

.tax_year_wrapper select {
    min-width: 200px;
    padding: 8px;
}
.zipcode_wrapper .zipinput{
    max-width: 300px;
}
.zipcode_wrapper input{
    width: 100%;
    padding: 8px;
}
.yearly-totals-container table {
    max-width: 700px;
    margin: auto;
    margin-top: 30px;
}
.export-btn-wrapper button {
    margin: 30px 0 10px 0;
}
.export-btn-wrapper {
    text-align: center;
}
.custom-report-card .card-body {
    padding: 30px 50px;
}

.custom-report-card .card-body {
    padding: 30px 50px;
}

.custom-report-form h3 {
    font-weight: 700;
    font-size: 24px;
    border-bottom: 2px solid;
    padding-bottom: 6px;
    margin-bottom: 15px;
}

.custom-report-form .form__form-group-label {
    font-size: 15px;
}

.custom-report-form input:not([type="checkbox"]),
.custom-report-form select {
    width: 100%;
    padding: 13px 10px;
    border: 1px solid #E9ECEF;
    line-height: 1;
    border-radius: 0px;
    outline: 0;
}
.custom-report-form input:not([type="checkbox"]):focus,
.custom-report-form select:focus{
    border-color: #009adc;
}

.custom-report-form h3:not(:first-of-type) {
    margin-top: 20px;
}
.form-group-three-controlled > div:nth-child(2) {margin: 0 3.5%;}

.form-group-three-controlled {
    justify-content: flex-start !important;
}
.form__form-group-icon{
    border: 1px solid #E9ECEF;
}
.form__form-group-icon + input,
.form__form-group-icon + select{
    border-left: none !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.custom-report-btns .search-btn{
    color: #313a46 !important;
    background-color: #ffbc00 !important;
    border-color: #ffbc00 !important;
    -webkit-box-shadow: 0px 2px 6px 0px rgba(255, 188, 0, 0.5) !important;
    box-shadow: 0px 2px 6px 0px rgba(255, 188, 0, 0.5) !important;
}
.custom-report-btns .search-btn svg{
    fill: #313a46 !important;
}
.custom-report-btns .search-btn:before,
.custom-report-btns .search-btn:after{
    display: none;
}

label.checkbox-label input {
    margin-right: 5px;
    margin-bottom: 2px;
}

label.checkbox-label:not(:first-child) {
    /* margin-left: 20px; */
}

label.checkbox-label {
    display: flex;
    align-items: center;
    font-size: 14px;
    white-space: nowrap;
}