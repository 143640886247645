.newclients-view-btn{
    border: none;
    background: none !important;
    padding: 0px;
    color: #383938 !important;
    margin-bottom: 0;
}
.newclients-view-btn:before,
.newclients-view-btn:after{
    display: none;
}
.dashboard-card-title p {
    font-size: 18px;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    color: #383938;
}
.dashboard-card-title p svg:not(.controlled) path {
    fill: #009ADC;
}

.dashboard-card-title p svg{
    width: 32px;
    height: 32px;
    margin-right: 10px;
}
.dashboard-card-title p svg.controlled{
    width: 25px;
    height: 25px;
    margin-right: 10px;
    margin-bottom: 5px;
}
.dashboard-viewall-btn {
    padding: 6px 0;
    display: block;
    color: #009adc;
    font-size: 13px;
}

.newclientsproperties-wrapper,
.recentactivities-wrapper {
    padding-left: 0 !important;
    
}
.newclientsproperties-wrapper a,
.recentactivities-wrapper a {
    color: #383938;
    font-size: 14px;
    border: none !important;
    box-shadow: none !important;
    border-bottom: 2px solid transparent !important;
    font-weight: 500;
}
.newclientsproperties-wrapper a.active,
.recentactivities-wrapper a.active {
    border-bottom: 2px solid #FFA647 !important;
}
.recent-viewed-list {
    padding: 0;
    list-style: none;
}


.recent-viewed-list a {
    display: block;
    padding: 15px 0;
    font-weight: 600;
    font-size: 14px;
    color: #383938;
}

.recent-viewed-list li:not(:last-child) {
    border-bottom: 1px solid #EAEDF2;
}
.report-btn {
    display: block;
    padding: 20px 13px;
    border-radius: 5px;
    border: 1px solid #FFA647;
    margin-bottom: 25px;
}

.report-btn p {
    font-family: Raleway;
    color: #383938;
    font-size: 14px;
    margin-top: 10px;
    font-weight: 600;
}

.report-btn svg {
    width: 30px;
    height: 30px;
}