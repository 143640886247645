thead td {
    font-weight: bold;
}

tbody tr {
    border-top: 1px solid #ccc
}

tbody td {
    padding: 10px 0;
}