@import url('https://fonts.googleapis.com/css2?family=Arizonia&display=swap');

.form_form-group-field-container *:not(:last-child) {
    margin-right: 15px;
}
.form__form-group-label,
.form__form-group-radio label{
    display: block;
}
.form_form-group-field-container *{
    flex: 1;
}
.form_form-group-field-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.form input[type="checkbox"],
.form input[type="radio"]{
    height: auto;
    width: auto;
}

.select-light-border {
    border: 1px solid #f2f4f7;
    color: #646777;
}

.select-light-border:focus {
    border-color: #4ce1b6;
}

.form__form-separator .form__form-group-field label {
    margin-bottom: 0;
    margin-left: 5px;
}

.form__form-separator .form__form-group-field {
    padding-bottom: 20px;
}

.form__form-separator .form__form-group-field input {
    margin-top: 3px;
}
.check-container-with-border {
    padding: 10px !important;
    margin-bottom: 20px;
    border: 1px solid;
}
.terms-cond-modal .modal-dialog {
    max-width: 800px;
}
.terms-cond-modal .modal-dialog .modal-content {
    overflow-y: auto;
    height: 100%;
    max-height: calc(100vh - 3.25rem);
    padding: 10px 30px 30px;
}
.client-signature{
    font-family: Arizonia;
    font-size: 50px;
}
.register-form textarea{
    border-radius: 0px;
    outline: 0;
}
.register-form textarea:focus{
    border-color: #009ADC !important;
}

.register-form input{
    padding: 13px 10px;
    height: auto;
    border-radius: 0px;
    outline: 0;
}
.register-form input:focus{
    border-color: #009ADC !important;
}
.register-form select{
    padding: 12px 10px;
    height: auto;
    border-radius: 0px;
    outline: 0;
}
.register-form select:focus{
    border-color: #009ADC !important;
}
.register-form .form__form-group-icon{
    padding: 10px 15px;
}
.register-form input[type="password"] + button{
    padding: 11px 15px;
    height: auto;
}
.register-form .form__form-group-field {
    align-items: flex-start;
}
.client-term-footer {
    text-align: right;
    padding-top: 50px;
}

.eye-icon{
    position: absolute;
    right: 0px;
    margin-top: 9px;
    cursor: pointer;
}

.form__form-group-input-wrap select {
    width: 100%;
}

.error-field{
	border: 1px solid #721c24 !important;
}
