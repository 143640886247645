$yellow: #ffbc0080;
$blue: #009adc80;
$placeholder: #c3c3c3;
$theme-yellow: #ffa647;
$theme-blue: #009adc;
$theme-dark: #012d61;

body {
    color: #6c757d !important;
    // font-family: "Nunito", sans-serif !important;
}

.color-secondary {
    color: #ffa647 !important;
}
.bold {
    font-weight: bold;
}

.container {
    padding-bottom: 10px;
}

.table-bold {
    font-weight: 900;
    font-size: 16px;
}
.modal {
    background: #00000059 !important;
    z-index: 9989 !important;
}

.btn-sm {
    padding: 5px 25px !important;
}

.cursor-pointer {
    cursor: pointer;
}

.form_two_columns {
    display: flex;
    justify-content: space-between;
    // &:nth-child(1){
    //  margin-right: 50px;
    // }
}

// .form_two_columns>.form__form-group:nth-child(){
//   margin-right: 50px;
// }

.form_two_columns .form__form-group {
    width: 48%;
}

.client-registration-form-container form input,
.client-search-container form input {
    height: 46px !important;
}



.empty-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

// old css
.client-reg-form {
    max-width: 700px;
    margin: auto;
    .form__form-group-label {
        font-weight: 700;
    }
}
.form__form-group-client-fields {
    display: flex;
    width: 100%;
    > div {
        &:not(:last-child) {
            margin-right: 20px;
        }
        input {
            width: 100%;
        }
        flex: 1;
    }
}
.client-search-container {
    .card-body {
        .row {
            max-width: 700px;
            margin: auto;
        }
    }
    form {
        input {
            width: 100%;
            padding: 8px;
            border: 1px solid rgb(118, 118, 118);
            width: 100%;
            border: 1px solid #e9ecef;
            padding: 8px 15px;
        }
        select {
            width: 100%;
            padding: 8px;
            border: 1px solid rgb(118, 118, 118);
            width: 100%;
            padding: 13px 8px;
            border-radius: 2px;
            border: 1px solid #e9ecef;
        }
    }
}
.form__form-group-two {
    > * {
        flex: 0 0 48%;
    }
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.client-details-left {
    > * {
        &:not(:first-child) {
            &:not(:last-child) {
                margin: 4px 0px 15px;
            }
        }
    }

    h3 {
        color: #383938;
        font-family: "Raleway", sans-serif;
        font-size: 24px;
    }
    > span {
        display: inline-block;
    }
    .outstanding-icon {
        margin-right: 10px !important;
        transform: translateY(-2px);
    }
    .toggle-switch {
        margin-top: -4px;
    }
    // display: flex;
    // align-items: center;
}
.is-active {
    background-color: #0bce97;
    border-radius: 4px;
    padding: 2px 6px;
    color: white;
}

.not-active {
    border-radius: 4px;
    padding: 2px 6px;
    color: #313a46;
    background-color: #e3eaef;
}
.no-balance,
.with-balance {
    font-size: 16px;
    font-weight: 500;
    font-family: "Raleway", sans-serif;
}
.no-balance {
    color: #00c48c;
}
.with-balance {
    color: #fa5c7c;
}

.client-details-body {
    padding: 33px 30px;
    margin: 1.25rem 0;
    background: rgba(231, 242, 250, 0.5) !important;
    box-shadow: 0px 3px 3px 0px #ececf1 !important;
    // height: auto;
    flex: unset;
}
.card-header {
    background: transparent !important;
    border: none;
    padding: 0;
    padding-bottom: 20px;
    // background-color: $theme-yellow;
    p {
        color: #383938;
        font-weight: 700;
        font-size: 14px;
        font-family: "Raleway", sans-serif;
    }
}
.card-content {
    p {
        font-family: "Raleway", sans-serif;
    }
    .card-detail-label {
        color: #787878;
        font-weight: 400;
        font-size: 12px;

        span {
            color: #383938;
            font-weight: 500;
        }
    }
    p:not(.card-detail-label) {
        margin-bottom: 20px;
        margin-top: 5px;
        color: #383938;
        font-weight: 500;
    }
    p:last-child {
        margin-bottom: 0;
    }

    .btn {
        background: white !important;
    }
}

.cardbody-controlled {
    height: auto !important;
    flex: unset;
    .nav {
        border-bottom: 2px solid rgba(152, 166, 173, 0.2);
        padding: 0;
        a {
            border: none;
            border-bottom: 2px solid transparent;
            color: #6c757d;
            font-weight: 600;
            &:hover {
                border-bottom: 2px solid $theme-yellow;
            }
        }
        a.active {
            border-bottom: 2px solid $theme-yellow;
        }
    }
}
.card-controlled {
    height: auto !important;
}
.client-activities-list {
    display: flex;
    padding: 20px 0 30px;
    &:not(:last-child) {
        border-bottom: 1px solid #eee;
    }
}
.client-avatar-wrapper {
    img {
        width: 50px;
        height: 50px;
        object-fit: cover;
    }
}
.client-activity-details {
    padding-left: 20px;
    flex: 1;
}
.client-meta {
    h3 {
        font-weight: 700;
        small {
            font-size: 14px;
        }
    }
}
.client-activity-content {
    padding: 15px 20px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    margin-left: 30px;
}
.property-search-btns {
    text-align: right;
}
.client-registration-form-container {
    form {
        select {
            width: 100%;
            padding: 13px 8px;
            border-radius: 2px;
            border: 1px solid #e9ecef;
            outline: 0;

            &:focus {
                border-color: #009adc;
            }
        }
        input {
            width: 100%;
            border: 1px solid #e9ecef;
            padding: 8px 15px;
            outline: 0;

            &:focus {
                border-color: #009adc;
            }
        }
        input[type="email"] {
            + {
                button {
                    width: auto;
                    margin: 0;
                    max-width: none !important;
                }
            }
            width: auto;
            flex: 1;
            height: auto;
            margin-right: 10px;
        }
    }
    textarea {
        width: 100%;
        border: 1px solid #e9ecef;
        padding: 8px 15px;
        outline: 0;

        &:focus {
            border-color: #009adc;
        }
    }
}
.client-create-btns,
.send-email-btns,
.edit-county-btns,
.property-search-btns {
    button {
        flex: unset;
        width: auto;
        &:after {
            display: none;
        }
        &:before {
            display: none;
        }
        svg {
            margin-bottom: 3px;
        }
    }
    display: flex;
    // justify-content: flex-end;
    .btn-primary {
        background: $theme-yellow !important;
        border-color: $theme-yellow !important;
        border-radius: 5px;
        color: #fff;
        transition: all 0.3s ease-in-out;
        -webkit-box-shadow: 0px 2px 6px 0px $yellow;
        box-shadow: 0px 2px 6px 0px $yellow;
        svg:not(.custom) {
            path {
                fill: #fff;
            }
        }
    }
}

.btn-secondary {
    border-color: $theme-yellow !important;
    background: transparent !important;
    color: #383938;
    border-radius: 5px;
    font-weight: 500;
    font-family: "Raleway", sans-serif;
    // padding: 5px 25px;
    &:before {
        display: none;
    }

    svg {
        margin-top: 0;
    }
}
.btn-primary {
    background: $theme-yellow !important;
    border-color: $theme-yellow !important;
    border-radius: 5px;
    color: #fff !important;
    transition: all 0.3s ease-in-out;
    -webkit-box-shadow: none;
    box-shadow: none;
    svg:not(.custom) {
        path {
            fill: #fff;
        }
    }
    &:before {
        display: none;
    }
    &:after {
        display: none;
    }

    svg.custom {
        fill: none !important;
    }
}

.btn-primary.primary-2 {
    padding: 5px 25px;
}

.btn-tertiary {
    border: none;
    background: none !important;
    padding: 0px;
    color: #383938 !important;
    margin-bottom: 0;

    &:before {
        display: none;
    }
}

.btn-cancel {
    background: rgba(56, 57, 56, 0.35) !important;
    color: white !important;

    &:before,
    &:after {
        display: none;
    }
}

.btn-secondary.secondary-2 {
    padding: 10px 25px;
}

.btn-secondary.secondary-inverted {
    border-color: white !important;
    color: white !important;
}
.client-search-btns {
    display: flex;
    justify-content: flex-end;
    button {
        &:after {
            display: none;
        }
        &:before {
            display: none;
        }
        svg {
            margin-bottom: 3px;
        }
    }
    .search_btn {
        background: $theme-yellow !important;
        border-color: $theme-yellow !important;
        border-radius: 5px;
        color: #fff;
        transition: all 0.3s ease-in-out;
        -webkit-box-shadow: 0px 2px 6px 0px $yellow;
        box-shadow: 0px 2px 6px 0px $yellow;
        svg {
            path {
                fill: #fff;
            }
        }
    }
}
.form__form-group-icon {
    padding: 12px 15px;
    height: auto;
    border-top-left-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
    background-color: #e9ecef !important;
    svg {
        path {
            fill: #495057;
        }
    }
}

.tab-content-title {
    color: #383938 !important;
    font-family: "Raleway", sans-serif;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 8px !important;
}
.clientNotes_wrapper {
    textarea {
        width: 100%;
        border: none;
        background-color: #f1f3fa;
        padding: 15px;
        border-radius: 4px;
    }
}
.client_notes {
    padding: 20px 0;
    button {
        background-color: $theme-yellow !important;
        border-color: $theme-yellow !important;
        border-radius: 2px;
        color: #313a46;
        padding: 10px 15px;
        height: auto;
        -webkit-box-shadow: 0px 2px 6px 0px rgba(255, 188, 0, 0.5);
        box-shadow: 0px 2px 6px 0px rgba(255, 188, 0, 0.5);
        &:before {
            display: none;
        }
        &:after {
            display: none;
        }
    }
}

.deleteClient-btn {
    background-color: $theme-yellow !important;
    border-color: $theme-yellow !important;
    border-radius: 2px;
    color: #313a46;
    padding: 10px 15px;
    height: auto;
    -webkit-box-shadow: 0px 2px 6px 0px rgba(255, 188, 0, 0.5);
    box-shadow: 0px 2px 6px 0px rgba(255, 188, 0, 0.5);
    &:before {
        display: none;
    }
    &:after {
        display: none;
    }
}

.sessionModal {
    svg {
        path {
            fill: #ff4761;
        }
        width: 45px;
        height: 45px;
    }
    .modal-content {
        padding: 20px 20px 10px 20px;
    }
    button {
        margin-top: 20px;
    }
    h3 {
        font-size: 18px;
        margin-bottom: 20px;
    }
}
.deleteClientModal {
    svg {
        path {
            fill: $theme-yellow;
        }
        width: 45px;
        height: 45px;
    }
    .modal-dialog {
        max-width: 452px;
    }
    .modal-content {
        border-radius: 5px;
        padding: 50px 50px 30px 50px;
    }
    button {
        margin-top: 20px;
    }
    h3 {
        margin-bottom: 20px;
        font-family: Raleway;
        font-size: 18px;
        line-height: 21px;
        color: #383938;
    }
    p {
        font-family: Raleway;
        color: #383938;
        font-size: 14px;
    }
    select {
        display: block;
        width: 100%;
        padding: 13px 10px;
        border-color: #ccc;
    }
}
// .client-details-right {
// 	button {
// 		svg {
// 			margin-bottom: 3px;
// 		}
// 		&:before {
// 			display: none;
// 		}
// 		&:after {
// 			display: none;
// 		}
// 		background-color: $theme-yellow !important;
// 		border: none;
// 		border-radius: 2px;
// 		-webkit-box-shadow: 0px 2px 6px 0px $yellow !important;
// 		box-shadow: 0px 2px 6px 0px $yellow !important;
// 		color: #313a46 !important;
// 		&:not(:last-child) {
// 			margin-right: 7px;
// 		}
// 	}
// }
.changePassbtn {
    &:before {
        display: none;
    }
    &:after {
        display: none;
    }
    background-color: $theme-yellow !important;
    border: none;
    border-radius: 2px;
    -webkit-box-shadow: 0px 2px 6px 0px $yellow !important;
    box-shadow: 0px 2px 6px 0px $yellow !important;
    color: #313a46 !important;
}
.changePasswordModal {
    .modal-dialog {
        max-width: 500px;
    }
    .modal-body {
        svg {
            path {
                fill: $theme-yellow;
            }
            width: 40px;
            height: 30px;
        }
        h3 {
            font-weight: 700;
            font-size: 20px;
            margin-top: 10px;
        }
        form {
            label {
                display: block;
            }
            input {
                width: 100%;
                padding: 8px 15px;
                border: 1px solid #eee;
                margin-bottom: 15px;
            }
        }
        label {
            font-weight: 700;
        }
        p {
            margin-bottom: 20px;
        }
    }
    .modal-content {
        padding: 20px 10px 10px 10px;
    }
}
.outstanding-edit {
    cursor: pointer;
    margin-left: 10px;
    svg {
        height: 15px;
        width: 15px;
    }
}
.controlled-row {
    margin: 0 -30px;
}
// .client-account-files-cont {
// 	h3 {
// 		color: #6c757d;
// 		font-size: 15px;
// 		font-weight: 700;
// 	}
// 	>div {
// 		box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.1);
// 	}
// }
.client_account_files {
    a {
        border: 1px solid #ffa647;
        width: 100%;
        display: flex;
        padding: 20px;
        border-radius: 5px;
        align-items: center;
        .filetype {
            background-color: $theme-blue;
            color: white;
            font-weight: 700;
            line-height: 45px;
            width: 45px;
            border-radius: 4px;
            display: inline-block;
            text-align: center;
            margin-right: 15px;
            font-size: 13px;
        }
        .filename {
            flex: 1;
            color: #383938;
            font-weight: 600;
            font-size: 14px;
            font-family: "Raleway", sans-serif;
        }
        .fileicon {
            padding: 0 20px;
            svg {
                path {
                    fill: #98a6ad;
                }
            }
        }
        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }
    // padding-top: 20px;
}
// .general-file-uploader {
// 	button {
// 		color: #6c757d;
// 		padding: 13px 20px;
// 		border: none;
// 	}
// }

//end old css

//placehoder
::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $placeholder;
}
::-moz-placeholder {
    /* Firefox 19+ */
    color: $placeholder;
}
:-ms-input-placeholder {
    /* IE 10+ */
    color: $placeholder;
}
:-moz-placeholder {
    /* Firefox 18- */
    color: $placeholder;
}
//end placeholder

.form-select option[disabled] {
    color: $placeholder !important;
}

.button-table {
    margin-bottom: 0px !important;
}

.btn-theme {
    background-color: $theme-yellow !important;
    color: #313a46 !important;
}

.btn-theme:hover {
    background-color: $theme-yellow !important;
}

.card-body {
    position: relative;
    box-shadow: 0px 2px 2px rgba(51, 51, 51, 0.05) !important;
}
.rdt_Pagination {
    justify-content: flex-start !important;
    transform: translateY(0px);
    padding-top: 30px;
}

.activity-table-wrapper {
    position: relative;

    > div {
        margin-bottom: 20px;
    }
    *:not(button) {
        border: none !important;
    }
    a {
        color: #ffa647 !important;
    }

    .rdt_TableCell {
        background: rgba(231, 242, 250, 0.5);
        padding: 20px;
        border-radius: 5px;

        > div {
            width: 100%;
        }
    }

    .rdt_TableRow:not(:last-child) {
        margin-bottom: 10px;
    }
    .viewall-activities-wrapper {
        right: 0;
    }
}
.nopager {
    padding-bottom: 30px;
}
.activity-details-fields {
    span {
        text-transform: uppercase;
        font-size: 14px;
    }
}

.viewall-activities-wrapper {
    position: absolute;
    // bottom: -30px;
    right: 15px;

    button {
        margin: 0;
    }
}
.activity-table-wrapper:not(.nopager) {
    .viewall-activities-wrapper {
        bottom: 20px;
    }
}
.activity-table-wrapper .rdt_TableHeadRow {
    display: none;
}

.align-center {
    align-items: center;
}
.data-row {
    margin-top: 10px;
}
.data-col {
    p {
        font-family: Raleway;
        font-size: 14px;
        color: #383938;
        margin-bottom: 20px;
    }
}
.action-btn-wrapper {
    text-align: right;

    button {
        margin-bottom: 0;
    }
}
.loginlogo-wrapper {
    display: flex;
    align-items: center;
}

.loginlogo-wrapper .topbar__logo {
    width: 160px;
}
.login-form,
.register-form {
    // .form__form-group-label, .checkbox-btn__label{
    // 	color: white;
    // }
    .checkbox-btn:hover {
        * {
            color: #787878;
            border-color: #787878;
        }
    }
    .btn-secondary.account__btn {
        color: white !important;
    }
    // i, h3, label{
    // 	color: white;
    // }
    .btn-secondary.create-account-btn {
        color: #383938 !important;
        padding: 10px 25px !important;
    }
}
.loginformadmin-wrapper .account__card {
    max-width: 550px;
}

.login-form-admin .form__form-group {
    display: block;

    &:not(:last-child) {
        margin-bottom: 10px;
    }
}

.account__have-account {
    font-size: 14px;
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    color: #383938;
    // p{
    // 	color: white;
    // }
    a {
        color: #ffa647;
    }
}
.notes-text {
    width: 100%;
    background-color: #fff !important;
    border-color: #eaedf2 !important;
    padding: 15px;
    color: black;
    outline: 0;
    border-radius: 0px;

    &:focus {
        border-color: #009adc !important;
    }
    &::placeholder {
        color: #787878;
    }
}

.rdt_TableHeadRow * {
    font-weight: 700 !important;
    font-size: 12px;
    font-family: "Raleway", sans-serif;
}

.activity_user {
    color: #383938 !important;
    font-family: "Raleway", sans-serif;
    font-size: 14px;
}
.topbar__avatar-name {
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #383938;
    margin: auto !important;

    span {
        color: #ffa647;
    }
}
.login_header {
    padding-top: 40px;

    h1 {
        font-size: 24px;
        font-weight: 700;
        font-family: "Raleway";
        color: #383938;
        margin-bottom: 20px;
    }
}
.admin-create-account {
    margin: 0;
    margin-left: 20px;
    line-height: 1.3;
    align-self: center;
    font-weight: 500;
    color: #383938 !important;
    font-family: "Raleway";
}
.custom-404-content {
    height: 100vh !important;
    display: flex !important;
    width: 100vw !important;
    align-items: center !important;
    justify-content: center !important;
}
.custom-404 p {
    font-size: 14px;
    color: #787985;
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: -5px;
}
.acknowledgment_form {
    max-width: 100% !important;

    textarea {
        width: 100%;
        padding: 14px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 16px;
        color: black;
        line-height: 1.3;
    }
}

.acknowledgment-status h4 {
    color: #383938;
    font-weight: 600;
    line-height: 1.5;
    font-size: 16px;

    &:not(:last-child) {
        margin-bottom: 5px;
    }

    .success {
        color: green;
    }
    .error {
        color: #e7532c;
    }

    .normal {
        color: blue;
    }
    .none {
        color: #383938;
    }
}

.exportbtn-wrapper {
    button {
        margin-right: 0;
        width: 100%;
        padding: 10px 25px;
    }
}
div[role="row"] {
    > div {
        padding: 20px 16px;
    }
    > div:first-child {
        padding-left: 0;
    }
    > div:last-child {
        padding-left: 0;
        padding-right: 0;
    }
}

.activity-table-wrapper {
    div[role="row"] {
        > div:last-child {
            padding-left: 16px;
            padding-right: 16px;
        }
    }
}

.tab-counter {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    background: $theme-yellow;
    color: white;
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: inline-block;
    padding: 1px 0;
    margin-left: 5px;
}

.tab-length-2 {
    width: 22px;
    height: 22px;
    padding: 1px 1px;
    padding-top: 2px;
    font-size: 12px;
}

.tab-length-3 {
    width: 24px;
    height: 24px;
    padding: 1px 1px;
    padding-top: 3px;
    font-size: 12px;
}
.tab-length-4 {
    width: 30px;
    height: 30px;
    padding: 1px 1px;
    padding-top: 7px;
    font-size: 12px;
}
.tab-length-5 {
    width: 32px;
    height: 32px;
    padding: 1px 1px;
    padding-top: 9px;
    font-size: 10px;
}

@media only screen and (max-width: 1250px) {
    .custom-datatable-pagination-wrapper {
        max-width: 250px !important;
    }
}
@media only screen and (max-width: 1138px) {
    .custom-datatable-pagination-wrapper {
        max-width: 200px !important;
    }
}

@media only screen and (max-width: 1072px) {
    .custom-datatable-pagination-wrapper {
        max-width: 150px !important;
    }
}

.custom-datatable-pagination-wrapper {
    /* display: flex; */
    max-width: 500px;
    /* background-color: #333; */
    overflow: auto;
    white-space: nowrap;

    .disabled {
        cursor: auto;
        opacity: 0.4;
    }

    span {
        display: inline-block;
        width: 33px;
        height: 33px;
        border-radius: 50%;
        line-height: 33px;
        text-align: center;
        font-family: Raleway;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #383938;
        cursor: pointer;
    }

    span.current-page {
        background-color: #ffa647;
        color: white;
    }

    > div {
        display: inline-block;
        width: 33px;
        height: 33px;
        line-height: 33px;
        cursor: pointer;
    }

    .custom-next-arrow {
        text-align: right;
    }

    .custom-prev-arrow {
        text-align: left;
    }
}

.custom-datatable-pagination {
    display: flex;
    margin-top: 20px;
    align-items: center;
}

.custom-datatable-pagination ::-webkit-scrollbar {
    width: 14px;
}

.custom-datatable-pagination ::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #aaaaaa;
}

.custom-pagination-total {
    margin-left: 20px;
    min-width: 150px;
}
.note-list-item {
    margin-left: 0;
    margin-right: 0;
    background: rgba(231, 242, 250, 0.5);
    padding: 20px 5px;
    border-radius: 5px;
}
.client-reg-form {
    &.property-edit-form,
    &.add-property-form {
        max-width: 835px;
        margin-left: 0 !important;
    }
}
.userdetails-edit-form {
    // padding-top: 40px;
    margin-left: 0 !important;
}
.alert-with-icon {
    display: flex;
    align-items: flex-start;
}
.tooltip-icon,
.register-form .tooltip-toggle {
    display: inline-block;
    width: 16px;
    line-height: 13px;
    text-align: center;
    border-radius: 50%;
    border: 1.5px solid;
    margin-right: 12px;
    cursor: pointer;
    transform: translateY(3px);
}

.register-form .tooltip-toggle {
    transform: translateY(0px);
}
.custom-legal-alert {
    border-radius: 5px;
    padding: 15px 20px !important;

    p {
        font-weight: 500 !important;
        font-size: 16px !important;
    }
}
.property-edit-form,
.userdetails-edit-form,
.add-property-form {
    h4 {
        font-family: Raleway;
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        color: #383938;
        margin-bottom: 30px;
    }
    .form__form-group {
        display: flex;
    }
    .form__form-group-label {
        flex: 0 0 180px;
        padding-top: 10px;
        font-family: Raleway;
        font-weight: 500;
        color: #383938;
    }
    hr {
        margin: 60px 0;
    }
}

.form__options_container {
    flex: 1;

    textarea {
        border: 1px solid #eaedf2;
        padding: 13px 12px;
        font-family: Raleway;
        font-weight: 500;
        font-size: 13px;
        line-height: 1.3;
        color: black;

        &::placeholder {
            color: #787878;
        }
    }
}
.form__options_wrapper {
    display: flex;
    padding-top: 30px;

    label {
        display: flex;
        flex: 0 0 100px;
        font-family: Raleway;
        font-size: 16px;
        line-height: 19px;
        color: #383938;

        > div {
            margin-right: 10px;
            transform: translateY(2px);
            width: auto;
        }

        input {
            width: auto;
            height: auto !important;
            line-height: 1 !important;
        }
    }

    .form__form-group-input-wrap {
        position: relative;
        width: 16px;
        height: 16px;

        span:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            border: 1.5px solid #787878;
        }
        span:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            width: 8px;
            height: 8px;
            background: transparent;
        }

        input {
            position: absolute;
            opacity: 0;
            width: 100%;
            height: 100% !important;

            &:checked + span:after {
                background: #ffa647;
            }

            &:checked + span:before {
                border-color: #ffa647;
            }
        }
    }
}

.form__options_label {
    font-family: Raleway;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #383938;

    i {
        font-family: Raleway;
        font-size: 14px;
        line-height: 1.5;
        color: #787878;
        margin-top: 7px;
        display: block;
        font-style: normal;
    }
}

.form__numbered_options {
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px;
    flex: 0 0 100%;
}

.form__number_label {
    width: 24px;
    line-height: 18px;
    text-align: center;
    border-radius: 50%;
    background: #009adc;
    color: white;
    transform: translateY(-2px);
    margin-right: 20px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    padding: 2px 0 4px;
}

.form__optional_upload_container {
    padding-top: 30px;

    label {
        position: relative;

        input {
            top: 0;
            left: 0;
            position: absolute;
            opacity: 0;
            z-index: 1;
            cursor: pointer;
        }

        button {
            margin: 0;
        }
    }
}

.form__custom_tooltip a,
.register-form .tooltip-toggle {
    display: inline-block;
    width: 15px;
    text-align: center;
    line-height: 12px;
    border-radius: 50%;
    border: 1.5px solid #ffa647;
    margin-left: 15px;
    color: #ffa647 !important;
}

.form__conditional_field_container {
    display: flex;
    padding-top: 30px;

    .form__conditional_field-wrapper {
        flex: 1;

        textarea {
            min-height: 100px;
        }
    }
    .form__conditional_field-label {
        flex: 0 0 150px;
        font-family: Raleway;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 1.3;
        color: #383938;
        margin-right: 20px;
    }
    // .form__custom_tooltip a {
    // 	display: inline-block;
    // 	width: 15px;
    // 	text-align: center;
    // 	line-height: 15px;
    // 	border-radius: 50%;
    // 	border: 1px solid #383938;
    // 	margin-left: 15px;
    // }
}
.activity-changes,
.activity-changes-wrapper {
    .highlight {
        color: #ffa647;
        background: transparent;
    }
    p {
        line-height: 20px;

        span:not(.highlight) {
            text-transform: uppercase;
            font-size: 14px;
        }
    }
}

.custom-tooltip-popper {
    opacity: 1 !important;
    > div {
        background: #ffffff !important;
        box-shadow: 0px 0px 10px rgba(51, 51, 51, 0.1) !important;
        border-radius: 5px !important;
        font-family: Raleway;
        font-size: 14px;
        line-height: 1.5 !important;
        color: #383938;
        text-align: left;
        padding: 16px !important;
        min-width: 250px;
        opacity: 1 !important;
    }
}
.custom-tooltip-arrow {
    opacity: 0;
}
.account-alert {
    background-color: #ffa647 !important;
    border-color: #ffa647 !important;
    color: white;
    padding: 15px;
    border-radius: 5px;

    &.alert-primary {
        background-color: #009adc !important;
        border-color: #009adc !important;
    }

    p {
        font-size: 16px;
    }
}
.docu-tooltip {
    transform: translateY(-10px);
    display: inline-block;
}
.account-alert .row {
    width: calc(100% + 30px);
    align-items: center;
}

.property-status-wrapper,
.property-hearingwrapper {
    display: flex;

    span {
        white-space: nowrap;
        margin-right: 15px;
        font-family: Raleway;
        font-weight: 600;
        font-size: 13px;
        line-height: 15px;
        color: #383938;

        a {
            width: 18px;
            line-height: 18px;
            border-radius: 50%;
            border: 1px solid;
            display: inline-block;
            text-align: center;
            margin-left: 10px;
            cursor: pointer;
        }
    }

    p {
        font-family: Raleway;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        color: #383938;
    }
}
.detail-page-heading {
    font-family: Raleway;
    font-style: normal;
    font-size: 24px;
    line-height: 28px;
    color: #383938 !important;
    margin-top: 5px;
}
.property-county-detail {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #787878;
    margin-top: 20px;
    display: block;

    i {
        font-style: normal;
        color: #ffa647;
    }
}

.heading-fileuploader {
    display: flex;
    justify-content: space-between;

    .general-file-uploader {
        position: relative;

        input {
            position: absolute;
            top: 0;
            right: 0;
            height: 43px;
            opacity: 0;
            z-index: 1;
            cursor: pointer;
        }

        button {
            padding: 5px 15px !important;
        }
    }
}
.hearing-aoa-dates {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.custom-datepicker {
    position: relative;
    span {
        position: absolute;
        right: 21px;
        height: 100%;
        width: 38px;
        text-align: center;
        display: flex;
        align-items: center;
        top: 0;
        justify-content: center;
        margin: 0;
        background-color: #eaedf2;
        pointer-events: none;
    }

    &.readonlydatepicker input[readonly] {
        cursor: auto;
        pointer-events: none;
    }
}

.inline-radio-button {
    .form__form-group-input-wrap {
        display: inline-block;
        width: auto;
        margin-right: 6px;

        input {
            transform: translateY(2px);
        }
    }
}
.inline-checkbox-field {
    .form__form-group-input-wrap {
        display: inline-block;
        width: auto;
        margin-right: 6px;
    }
}

.docu-delete {
    font-family: Raleway;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #383938;

    svg path {
        // fill: #FFA647 !important;
    }
}
.modalconfirm-closebtn {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    margin: 0 !important;
    padding: 0;
    z-index: 1;

    svg {
        width: 15px;
        height: 15px;
    }
}
.propdetails-collapsable {
    display: flex;
    justify-content: space-between;

    span {
        transition: all 0.3s ease-in-out;
        transform: rotate(180deg);

        &.active {
            transform: rotate(0deg);
        }
    }
}

.docu-file-item {
    display: flex;
    justify-content: space-between;
    background: rgba(231, 242, 250, 0.5);
    border-radius: 5px;
    padding: 33px 23px;
}

.docu-file-item .item-path {
    width: 187px;
    word-wrap: break-word;
}

.docu-file-wrapper {
    padding: 15px 0;

    .docu-file-item {
        > div:first-child {
            font-family: Raleway;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #383938;
            display: flex;

            svg {
                margin-right: 10px;
            }
        }

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}

.modal-doc-upload .modal-dialog {
    max-width: 450px;

    .modal-content {
        padding-bottom: 50px;
        border-radius: 5px;
        padding-left: 60px;
        padding-right: 60px;

        h3 {
            font-family: Raleway;
            font-weight: bold;
            font-size: 18px;
            line-height: 21px;
            text-align: center;
            color: #383938;
        }
    }
    .dropzone p {
        font-family: Raleway;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #383938;

        svg {
            margin-right: 10px;
            transform: translateY(-2px);
        }
    }
}
.docu-modal-btns {
    display: flex;
    padding-top: 10px;

    button {
        flex: 1;
    }
}

.staff-reg-form .form__form-group-field {
    display: flex;
    flex-direction: column;
}

.staff-reg-form {
    max-width: 100% !important;
    padding: 20px 10px;

    h3 {
        margin-bottom: 20px;
        font-family: Raleway;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        color: #383938;
    }
    hr {
        margin: 30px 0;
    }
    .form__form-group {
        display: flex;

        .form__form-group-label {
            flex: 0 0 200px;
            font-family: Raleway;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 15px;
            color: #383938;
            margin-top: 13px;
        }

        input,
        select {
            border: 1px solid #eaedf2;
            padding: 11px 10px;
        }
    }

    .form_two_columns.staff-form {
        > div:last-child {
            flex: unset;
            width: auto;
            padding-left: 30px;

            .form__form-group-label {
                flex: unset;
            }
            .form__form-group-field {
                flex: unset !important;
                width: auto !important;
                margin: 6px 0;
                margin-left: 20px;
            }
        }
        > div:first-child {
            flex: 1;
        }
    }
}
.property-description-card {
    padding: 25px 20px;
}
.prop-description-list-item {
    .prop-description-list-question {
        display: flex;
        align-items: flex-start;

        p {
            margin: 0;
            font-family: Raleway;
            font-weight: bold;
            font-size: 18px;
            line-height: 21px;
            color: #383938;
        }

        span {
            min-width: 20px;
            min-height: 20px;
            line-height: 20px;
            border-radius: 50%;
            background-color: #009adc;
            text-align: center;
            font-size: 12px;
            color: white;
            font-family: Raleway;
            font-style: normal;
            font-weight: 500;
            margin-right: 12px;
        }
    }
    > p {
        font-family: Raleway;
        font-weight: 600;
        font-size: 14px;
        color: #383938;
        margin: 25px 0;

        span {
            font-family: Raleway;
            font-weight: normal;
            font-size: 12px;
            color: #787878;
            display: block;
            line-height: 1.5;
            margin-bottom: 5px;

            &:last-child {
                color: #383938;
                font-size: 14px;
            }
        }
    }
    a {
        // display: flex;
        display: inline-block;
        font-family: Raleway;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #383938;

        svg {
            margin-right: 10px;
            transform: translateY(-1px);
        }
    }

    a + a {
        margin-left: 40px;
    }

    &:not(:last-child) {
        margin-bottom: 50px;
    }
}
#hearingTooltip {
    width: 20px;
    color: #ffa647;
    border-color: #ffa647;
}

.hearing-status-datetimepicker {
    transform: translateY(-8px);
    display: flex;

    > div:first-child {
        padding-right: 20px;
    }
    .MuiInput-underline {
        &:before,
        &:after {
            border: none !important;
        }
    }
    input {
        border: 1px solid #eaedf2;
        padding: 8.5px 11px;
        cursor: pointer;
    }
}
.logout-sidebar-link {
    margin-top: 80px;

    svg {
        transform: rotate(90deg);
    }
}

.white-nowrap {
    white-space: nowrap;
}
.recent-viewed-list {
    a {
        position: relative;
        padding-right: 20px;
        font-family: "Raleway";
        font-weight: 600;

        svg {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
.datatable-tab-container {
    padding: 0 !important;
    border-bottom: 1px solid #eaedf2;

    a {
        border: 2px solid transparent !important;
        font-family: Raleway;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #383938 !important;
        padding: 10px 25px 16px;

        &.active {
            border-bottom-color: #ffa647 !important;
        }
    }
}

.no-view-all .viewall-activities-wrapper {
    display: none;
}
.aoa-data-wrap {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    .aoa-data {
        font-family: Raleway;
        font-size: 14px;
        line-height: 16px;

        &:not(:last-child) {
            margin-bottom: 15px;
        }

        svg {
            margin-right: 10px;
            margin-top: -2px;
        }
    }
    .aoa-error {
        color: #e7532c;
    }
    .aoa-success {
        color: #00c48c;
    }
}

.backto-link {
    font-family: Raleway;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #383938;

    svg {
        margin-top: -3px;
        margin-right: 10px;
    }
}
.rdt_TableBody {
    .btn {
        padding: 5px 12px !important;
        min-width: 70px;
        text-align: center;
    }

    .toggle-switch-inner:before {
        background-color: #ffa647;
    }
}

.moderationModal {
    .modal-content {
        padding: 30px 20px;
    }
    h3 {
        color: #383938;
        font-size: 18px;
        line-height: 1.3;
    }

    p {
        color: #383938;
        font-size: 14px;
    }

    .btn:not(.modalconfirm-closebtn) {
        min-width: 100px;
        text-align: center;
    }
}
.dashboard-card-title {
    display: flex;
    justify-content: space-between;
}

.prop-descriptions-docs {
    padding: 15px 0;

    > div {
        display: flex;
        width: 100%;
    }

    &:not(:first-child) {
        border-bottom: 1px solid #e7f2fa;
    }

    &:nth-last-child(2) {
        margin-bottom: 20px;
    }

    .filename {
        flex: 1;
        padding-left: 12px;
        font-family: Raleway;
        font-weight: 500;
        font-size: 14px;
        color: #383938;
    }
    .docu-delete {
        text-align: right;
    }
}

.justify-center {
    display: flex;
    justify-content: center;
}

.btn-secondary.disabled,
.btn-secondary:disabled,
.btn-primary:disabled {
    color: #787985 !important;
}

.modal-sm {
    max-width: 300px;
}
.modal-lg {
    max-width: 700px;
}

.modal-md {
    max-width: 500px;
}

.text-right {
    float: right;
}

.document-card {
    display: flex;
    justify-content: space-between;
}

// switch
// Colors
$label-colour: #787878;
$disabled-colour: #313a46;
$toggle-colour: #00c48c;
$white: #fff;
$grey-color: #313a46;
$focus-color: #ff0;

.toggle-switch {
    position: relative;
    margin-right: 0px;
    width: 50px;
    display: inline-block;
    overflow: hidden;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    text-align: left;
    &-checkbox {
        display: none;
    }
    &-label {
        display: block;
        overflow: hidden;
        cursor: pointer;
        border: 0 solid $label-colour;
        border-radius: 20px;
        margin: 0;
        &:focus {
            outline: none;
            > span {
                box-shadow: 0 0 2px 5px red;
            }
        }
        > span:focus {
            outline: none;
        }
    }
    &-inner {
        display: block;
        width: 200%;
        margin-left: -100%;
        transition: margin 0.3s ease-in 0s;
        &:before,
        &:after {
            display: block;
            float: left;
            width: 50%;
            height: 30px;
            padding: 0;
            line-height: 34px;
            font-size: 11px;
            color: white;
            box-sizing: border-box;
        }
        &:before {
            content: attr(data-yes);
            text-transform: uppercase;
            padding-left: 10px;
            background-color: $toggle-colour;
            color: $white;
        }
    }
    &-disabled {
        background-color: $disabled-colour;
        cursor: not-allowed;
        &:before {
            background-color: $disabled-colour;
            cursor: not-allowed;
        }
    }
    &-inner:after {
        content: attr(data-no);
        text-transform: uppercase;
        padding-right: 10px;
        background-color: $label-colour;
        color: #313a46;
        text-align: right;
    }
    &-switch {
        display: block;
        width: 24px;
        margin: 3px;
        background: #f1f3fa;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 20px;
        border: 0 solid $label-colour;
        border-radius: 20px;
        transition: all 0.3s ease-in 0s;
    }
    &-checkbox:checked + &-label {
        .toggle-switch-inner {
            margin-left: 0;
        }
        .toggle-switch-switch {
            right: 0px;
        }
    }
    &.small-switch {
        width: 40px;
        .toggle-switch-inner {
            &:after,
            &:before {
                content: "";
                height: 20px;
                line-height: 20px;
            }
        }
        .toggle-switch-switch {
            width: 16px;
            right: 20px;
            margin: 2px;
        }
    }
    @media screen and (max-width: 991px) {
        transform: scale(0.9);
    }
    @media screen and (max-width: 767px) {
        transform: scale(0.825);
    }
    @media screen and (max-width: 575px) {
        transform: scale(0.75);
    }
}

.btn-link {
    background-color: transparent !important;
    border: none;
    text-decoration: none;
}

.btn-link:hover {
    background-color: transparent !important;
}
//end switch

.table-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.table-loading p {
    color: #012d61;
}

//form
.edit-county-wrap {
    form {
        max-width: 700px;
        margin: auto;

        h3 {
            font-weight: 700;
        }

        input,
        select {
            width: 100%;
            padding: 13px 10px;
            border: 1px solid #ccc;
            border-radius: 0px;
            outline: 0;

            &:focus {
                border-color: #009adc;
            }
        }

        textarea {
            border-radius: 0px;
            outline: 0;

            &:focus {
                border-color: #009adc;
            }
        }
    }
}

.edit-county-btns {
    display: flex;
    justify-content: space-between;
}
.edit-county-btns .btn-primary,
.create-county-btn {
    -webkit-box-shadow: 0px 2px 6px 0px rgba(255, 188, 0, 0.5) !important;
    box-shadow: 0px 2px 6px 0px rgba(255, 188, 0, 0.5) !important;
    color: #fff !important;
    background-color: $theme-yellow !important;
    border-color: $theme-yellow !important;
}
.edit-county-btns .btn-primary:before,
.edit-county-btns .btn-primary:after,
.create-county-btn:before,
.create-county-btn:after {
    display: none;
}
//end form

.year-picker {
    width: 260px !important;
}

.selected-year {
    background: $theme-yellow !important;
}

.year-picker a {
    color: $theme-dark !important;
    font-weight: 100 !important;
}

.year-picker .current-year {
    color: $theme-dark !important;
}

.year-picker .nav-button {
    color: $theme-yellow !important;
}

.date-picker {
    width: 250px !important;
}

.breacrum-button {
    position: absolute;
    top: 0px;
    right: 17px;
}

// loadingcss
.loading-div {
    background-color: #e7f2fa;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ripple {
    text-decoration: none;
    color: #fff;
    width: 12px;
    height: 12px;
    background-color: #ffa647;
    margin: 0 auto;
    border-radius: 100px;
    -webkit-animation: ripple 0.7s linear infinite;
    animation: ripple 0.7s linear infinite;
}

@-webkit-keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 166, 71, 0.3),
            0 0 0 10px rgba(255, 166, 71, 0.3),
            0 0 0 30px rgba(255, 166, 71, 0.3),
            0 0 0 60px rgba(255, 166, 71, 0.3);
    }
    100% {
        box-shadow: 0 0 0 10px rgba(255, 166, 71, 0.3),
            0 0 0 30px rgba(255, 166, 71, 0.3),
            0 0 0 60px rgba(255, 166, 71, 0.3), 0 0 0 90px rgba(255, 166, 71, 0);
    }
}

@keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 166, 71, 0.3),
            0 0 0 10px rgba(255, 166, 71, 0.3),
            0 0 0 30px rgba(255, 166, 71, 0.3),
            0 0 0 60px rgba(255, 166, 71, 0.3);
    }
    100% {
        box-shadow: 0 0 0 10px rgba(255, 166, 71, 0.3),
            0 0 0 30px rgba(255, 166, 71, 0.3),
            0 0 0 60px rgba(255, 166, 71, 0.3), 0 0 0 90px rgba(255, 166, 71, 0);
    }
}

//end loading css

.preview-cont {
    // border: 1px solid #ccc;
    // padding: 15px;

    margin-bottom: 20px;
}
.jodit-status-bar__item-right:last-child{
    display: none !important;
}

.page-breadcrumbs a:hover {
    text-decoration: underline;
}

#pagination-next-page,
#pagination-previous-page {
    background-color: $theme-yellow !important;
}

#pagination-next-page:hover,
#pagination-previous-page:hover {
    opacity: 0.7;
}
#pagination-previous-page {
    margin-right: 5px;
}

#pagination-next-page svg,
#pagination-previous-page svg {
    fill: #fff;
}

.no-overflow {
    white-space: no-wrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.aoa-ready {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.aoa-ready li {
    padding: 5px;
}

.aoa-ready li.good {
    color: #00c48c;
    // background-color: #cef5ea;
    // border-color: #baf2e2;
}

.aoa-ready li.not-good {
    color: #e7532c;
    // background-color: #fedee5;
    // border-color: #fed1da;
}

.activities-row {
    background: rgba(231, 242, 250, 0.5);
    border-radius: 5px;
    padding: 23px;
}

.flex-input {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
}

.flex-input .input-1 {
    display: flex;
    flex: 1;
}

.navbar__link--active {
    color: $theme-blue !important;
}

// [data-tag] {
//   width: 100vw;
// }

.sidebar-nav-wrapper {
    padding-top: 20px;
}

.navbar__link--active {
    svg {
        path {
            color: $theme-blue !important;
        }
    }
}

.account--not-photo {
    display: flex;
    flex: 1;
}
.loginpage-header {
    // position: fixed;
    // top: 0;
    // left: 0;
    width: 100%;
    background: white;
}
.loginlogo-wrapper {
    max-width: 874px;
    margin: auto;
}
.loginpage-footer {
    background-color: #064b65;
    padding: 19px 30px;

    .loginfooter-wrapper {
        max-width: 1300px;
        margin: auto;
    }

    p {
        color: white;
        font-family: "Raleway";
        font-size: 14px;
        font-weight: 400;

        span {
            opacity: 0.75;
        }

        a {
            font-weight: 500;
            color: white;
            margin-left: 20px;
        }
    }
}

/* RESPONSIVENESS */
@media (max-width: 768px) {
    .login_header {
        padding-top: 0;
    }
    .loginform-wrapper .form__form-group {
        flex-direction: column;

        > span {
            flex: 1;
        }
    }
    .loginlogo-wrapper a {
        height: 80px;
        display: block;
        width: 130px;
    }
    .loginpage-footer {
        padding: 15px 20px;

        p {
            display: flex;
            flex-direction: column-reverse;

            a {
                margin-left: 0;
                margin-bottom: 10px;
            }
        }
    }
    .loginform-wrapper .account__card.admin-card {
        padding-bottom: 10px;
    }
    .remember-me-label {
        margin: 0 !important;
    }
    .admin-create-account {
        text-align: center;
        margin-top: 20px;
    }
    .account__btns {
        flex-direction: column;

        > button:not(:last-child) {
            margin-bottom: 10px !important;
        }

        > * {
            margin-left: 0 !important;
        }
    }
}

.property-desc-home {
    width: 100%;
}

.required-indicator {
    color: #f44336 !important;
    font-size: 26px;
    position: absolute;
}

.error-field input {
    border: 1px solid #721c24 !important;
}

.alert-content {
    padding: 5px;
}

.toaster-div {
    background-color: red !important;
}

.btn-close {
    background: #feae5b29;
    padding: 4px 4px;
    border-radius: 20px;
    cursor: pointer;
}

.button-with-spinner {
    position: relative;

    > div[role="status"] {
        position: absolute;
        top: calc(50% - 6px);
        left: calc(50% - 8px);
    }

    &.disabled span {
        opacity: 0;
    }
}

.btn-info {
    background: #019bdb !important;
    color: #fff;
    border-color: #019bdb !important;
}

.btn-info:hover {
    color: #fff !important;
    opacity: 0.7;
}

.btn-success {
    background: #0bce97 !important;
    color: #fff;
    border-color: #0bce97 !important;
}

.btn-success:hover {
    color: #fff !important;
    opacity: 0.7;
}

.delete-aoa {
    border-radius: 4px;
    height: 37px;
    width: 37px;
    padding: 0px;
    margin: 0px;
    padding-left: 4px;
    margin-left: -15px;
    margin-top: -8px;
}

.left-action {
    display: flex;
    align-items: center;
    justify-content: end;
}

.multi-upload-files {
    border: 1px solid #ffa647;
    width: 100%;
    padding: 20px 15px;
    border-radius: 2px;
    margin-top: 20px !important;
    border-radius: 5px;
}

.multi-upload-files.error {
    border: 1px solid #f44336;
}

.multi-upload-files > div {
    display: flex;
    align-items: center;
}

.multi-upload-files .filetype {
    background-color: #009adc;
    color: white;
    font-weight: 600;
    line-height: 45px;
    width: 45px;
    border-radius: 4px;
    display: inline-block;
    text-align: center;
    margin-right: 15px;
    font-size: 11px;
    font-family: "Raleway", sans-serif;
}

.multi-upload-files .action {
    margin-top: 25px;
    justify-content: space-between;
}

.multi-upload-files .progress-wrapper {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: left;
    text-align: left;
}

.container-file {
	border: 5px solid transparent;
	margin-bottom: 10px;
    border-radius: 5px;
}

.container-file:hover {
	border: 5px solid #f6f6f6;
}


.multi-upload-action:hover{
    text-decoration: underline;
   color: #0494d1;
}

.edit-button{
    margin-left: 10px;
    margin-top: 10px;
    color: #ffa647;
    font-weight: 900;
    font-size: 14px;
    cursor: pointer;
}

.flex-al-cen{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 12px;

    label {
        margin: 0px;
    }

    .form__form-group-input-wrap {
        max-width: 24px;
        height: 46px;
    }
}

.client-registration-form-container 
form 
.form__form-group
.form__form-group-field
.flex-al-cen
input[type="checkbox"] {
    width: 24px;;
}


