.reset-password-form input{
    padding: 13px 10px;
    height: auto;
}
.reset-password-form button {
    position: relative;
}
.reset-password-form button > div[role="status"] {
    position: absolute;
    left: calc(50% - 82px);
    top: calc(50% - 7px);
}