.send-email-form {
    max-width: 700px;
    margin: auto;
}

.send-email-form h3 {
    font-weight: 500;
}
.send-email-form .form__form-group-label{
    font-weight: 700;
}
.send-email-form select, .send-email-form input, .send-email-form textarea  {
    width: 100%;
    padding: 13px 10px;
    border-radius: 4px;
    border: 1px solid #eee;
}

.send-email-form textarea {
    min-height: 120px;
}

/* .send-email-btns .search-btn,
.email-all-btn{
    color: #313a46 !important;
    background-color: #ffbc00 !important;
    border-color: #ffbc00 !important;
    -webkit-box-shadow: 0px 2px 6px 0px rgba(255, 188, 0, 0.5) !important;
    box-shadow: 0px 2px 6px 0px rgba(255, 188, 0, 0.5) !important;
} */
.send-email-btns .search-btn:before,
.send-email-btns .search-btn:after,
.email-all-btn:before, .email-all-btn:after{
    display: none;
}


/* .send-email-btns .search-btn svg path{
    fill: #313a46 !important;
} */

.send-email-loading {
    animation: rotate-loading 1s linear infinite;
}

@keyframes rotate-loading {
    from {
        transform: rotateZ(0deg);
    }

    to {
        transform: rotateZ(360deg);
    }
}