.profile-container {
    color: white;
}
.profile-container h2 {
    color: white;
    text-decoration: underline;
}
.static-fields .row > div div {
    font-size: 18px;
}

.static-fields, .editable-fields {
    padding-top: 20px;
}

.static-fields .row > div > div:not(:last-child) {
    margin-bottom: 7px;
}

.form-label{
    display: block;
    margin-top: 15px;
}
.account-form-btns {
    padding-top: 30px;
    text-align: right;
}