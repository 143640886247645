.account {
  height: 100vh;
  width: 100%;
  min-height: 100vh;
  display: flex;
  overflow-y: auto;
  flex-direction: column;

  @include themify($themes) {
    background: themed('colorBackgroundBody');
  }

  &.account--photo {
    background: url(../../shared/img/404/bg_404.png) no-repeat center!important;
    background-size: cover;

    .account__card {
      background-color: rgba(0, 10, 16, 0.8);
      color: $color-white;
    }

    .form__form-group-icon, .form__form-group-button {
      background: transparent;
    }

    .form__form-group-icon, input:not(:last-child) {

      @include directify($directions) {
        #{directed('border-right')}: none
      }
    }

    .form__form-group-button, input {

      @include directify($directions) {
        #{directed('border-left')}: none
      }
    }

    .form__form-group-button.active {

      svg {
        fill: $color-accent;
      }
    }

    input {
      color: $color-white;
      box-shadow: none;
    }

    p {
      color: $color-white;
    }

    .account__title {
      color: $color-white;
    }
  }
}

.account__wrapper {
  margin: auto;
  padding: 10px;

  @include directify($directions) {
    text-align: directed('left');
  }
}

.account__card {
  // background-color: #383938 !important;
  padding: 20px 60px 50px;
  border-radius: 5px;
  max-width: 520px;
  width: 100%;

  @include themify($themes) {
    background-color: themed('colorBackground');
  }
}
.checkbox-btn {
  .checkbox-btn__label{
    color: #383938 !important;
    font-size: 14px;
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
  }
}
.account__profile {
  text-align: center;
}

.register__btns {

  @include directify($directions) {
    #{directed('margin-left')}: 0px!important;
  }
}

.account__btns {
  display: flex;
  width: 100%;
  // margin-top: -10px;
  // margin-bottom: -20;
  margin: 0 !important;

  @include directify($directions) {
    #{directed('margin-right')}: 0px;
    #{directed('margin-left')}: -10px;
  }

  a {
    margin-bottom: 20px;
    white-space: nowrap;

    @include directify($directions) {
      #{directed('margin-right')}: 0px;
      #{directed('margin-left')}: 10px;
    }
  }
}

.account__btn {
  width: 100%;

  @include directify($directions) {
    #{directed('margin-right')}: 0px;
  }
}

.account__avatar {
  height: 64px;
  width: 64px;
  border-radius: 50%;
  overflow: hidden;
}

.account__name {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 15px;
  margin-top: 5px;
}

.account__sub {
  margin-top: 0;
  margin-bottom: 10px;
  color: $color-additional;
  font-size: 11px;
  line-height: 15px;
}

.account__forgot-password {
  position: absolute;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Raleway', sans-serif;
  line-height: 15px;
  bottom: -38px;
  z-index: 9;

  @include directify($directions) {
    #{directed('right')}: 0px;
  }

  a {
    color: #FFA647;

    &:hover {
      color: #FFA647;
      text-decoration: none;
    }
  }
}

.reset-password {
  bottom: 35px;

  &_arrow-left-icon {
    height: 9px;
    margin-right: -5px;
  }
}

.form__form-group--forgot {
  margin-bottom: 40px;
}

.account__or {
  text-align: center;
  margin-top: 35px;
  margin-bottom: 20px;
  position: relative;

  &:before, &:after {
    content: "";
    height: 1px;
    width: calc(50% - 90px);
    background: $color-dusty-white;
    position: absolute;
    top: 10px;
  }

  &:before {

    @include directify($directions) {
      #{directed('left')}: 0px;
    }
  }

  &:after {

    @include directify($directions) {
      #{directed('right')}: 0px;
    }
  }
}

.account__social {
  text-align: center;
}

.account__social-btn {
  display: inline-block;
  height: 38px;
  width: 38px;
  border-radius: 5px;
  padding: 9px;
  transition: all 0.3s;
  border: none;

  @include directify($directions) {
    #{directed('margin-right')}: 10px;
  }

  &:last-child {

    @include directify($directions) {
      #{directed('margin-right')}: 0px;
    }
  }

  svg {
    fill: $color-white;
    height: 20px;
    width: 20px;
  }

  &.account__social-btn--facebook {
    background: #4766a4;

    &:hover {
      background: darken(#4766a4, 10%);
    }
  }

  &.account__social-btn--google {
    background: #c74d4d;

    &:hover {
      background: darken(#c74d4d, 10%);
    }
  }

  &.account__social-btn--firebase {
    background: #4287f5;
    margin-top: 20px;
    padding: 0;
    padding-left: 5px;

    &:before {
      background-color: darken(#4287f5, 10%);
    }

    &:hover {
      background: darken(#4287f5, 10%);
    }
  }

  &.account__social-btn--auth0 {
    margin-top: 20px;
  }
}

.account__head {
  margin-bottom: 30px;

  @include directify($directions) {
    #{directed('padding-left')}: 10px;
    #{directed('border-left')}: 4px solid $color-blue;
  }
}

.account__logo {
  font-weight: 700;
}

.account__logo-accent {
  color: $color-blue;
}

.account__have-account {
  text-align: center;
  margin-top: 20px;

  a {
    color: $color-blue;
    transition: all 0.3s;

    &:hover {
      color: $color-blue-hover;
      text-decoration: none;
    }
  }
}

@media screen and (max-width: 520px) {

  .account__card {
    padding: 35px 30px;
  }
}

@media screen and (max-width: 425px) {

  .account__btns {
    flex-wrap: wrap;
  }
}

.register-page-wrapper .account__card {
  max-width: 834px;
}

.register-form {
  h4 {
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #383938;
    margin-bottom: 30px;
  }
  .form__form-group {
    display: flex;

    > span {
      white-space: nowrap;
      padding-top: 10px;
      padding-right: 30px;
      font-family: Raleway;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 15px;
      color: #383938;
      flex: 0 0 220px;
    }
  }

  hr {
    display: block;
    float: none;
    border-color: #EAEDF2;
    width: 100%;
    margin: 50px 0 70px;
  }

  .alert {
    padding: 10px 7px;
    text-align: left;

    p {
      text-align: left;
      font-family: Raleway;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
    }
  }

  .alert.alert-warning {
    background-color: #FFA647;
    border-color: #FFA647;
  }
  .alert.alert-primary {
    background: #009ADC;
    border-color: #009ADC;
  }

  .tooltip-toggle {
    width: 15px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #383938;
    color: #383938;
    cursor: pointer;
    margin-left: 10px;
  }
  input, select, textarea {
    border: 1px solid #EAEDF2 ;
  }
}

.register-page-wrapper .account__card {
  max-width: 834px;
}

.terms-cond-modal{
  .modal-body {
    h3 {
      font-family: Raleway;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      color: #383938;
      margin-bottom: 25px;
    }
    p {
      color: #383938;
      font-family: Raleway;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
    }
  }

  .modal-footer {
    border-top: none;
    justify-content: center;

    .btn-danger{
      background: rgba(56, 57, 56, 0.35);
      border-color: rgba(56, 57, 56, 0.35);

      &:before, &:after{
        display: none;
      }
    }
  }
}

.client-term-footer {
  display: flex;
}

.client-term-footer label {
  color: #383938;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  flex: 0 0 200px;
  text-align: left;
}

.client-term-footer input {
  padding: 13px 10px;
  height: auto;
  width: 100%;
  border: 1px solid #EAEDF2;
}

.form__electronic-signature {
  width: 100%;
}

.loginform-wrapper{
  .account__card {
    max-width: 834px;
  }

  .form__form-group {
    display: flex;
  }

  .form__form-group > span {
    flex: 0 0 200px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #383938;
    margin-top: 10px;
  }
  .account__btns .btn {
    width: auto;
  }
}